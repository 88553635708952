import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Home" keywords={["gatsby", "application", "react"]} mdxType="SEO" />
    <h1>{`Probleme?`}</h1>
    <p>{`Bald nicht mehr. Wir kümmern uns um jegliche Probleme.`}</p>
    <p>{`Von Gericht bis zu Brechstange. Einfach und unkompliziert.`}</p>
    <p>{`Schreiben Sie uns und wir werden uns umgehend bei Ihnen melden. `}<a href="mailto:alles-easy@geiler-anwalt.de">{`Kontakt`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      